/**
 * @author: Ahmed Samer
 * @date:   2020-05-11
 * @filename: aws-sdk.ts
 * @copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import CloudSearchDomain from "aws-sdk/clients/cloudsearchdomain";
import S3 from "aws-sdk/clients/s3";
import CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";
import AWS from "aws-sdk/global";
// import Iot from "aws-sdk/clients/iot";
// import IotData from "aws-sdk/clients/iotdata";

/**
 *
 *
 */
export const AWS_CLIENTS = {
  AWS,
  S3,
  CloudSearchDomain,
  CognitoIdentityServiceProvider,
  CognitoIdentity,
  // Iot,
  // IotData,
};
